<template>
  <v-card>
    <v-card-title>
      Company profile
      <v-spacer></v-spacer>
      <v-menu
        v-if="
          !isProd && company && company.plan && company.plan.plan !== 'free'
        "
        v-model="openActions"
        offset-y
      >
        <template v-slot:activator="{ on }">
          <v-btn v-show="isNotGuest" v-on="on" color="primary">Actions</v-btn>
        </template>

        <v-list>
          <v-list-item>
            <v-list-item-title>
              <v-btn
                class="ma-2"
                text
                color="primary"
                @click="resetSubscriptionCycle"
              >
                Reset Subscription Cycle
              </v-btn>
              <!-- <v-btn
                :disabled="!invoice.refundable"
                class="ma-2"
                text
                color="primary"
                :to="{
                  name: 'InvoiceCreditNote',
                  params: { id: invoice.stripeInvoiceId },
                }"
              >
                Refund
              </v-btn> -->
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>
              <v-btn
                :disabled="company.plan.plan === 'grandfather'"
                class="ma-2"
                text
                color="primary"
                @click="convertToGrandfatherPlan"
              >
                Convert to Grandfather Plan
              </v-btn>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-title>
    <v-simple-table>
      <template v-slot:default>
        <tbody>
          <tr>
            <td style="width: 300px">Company name</td>
            <td>
              <v-skeleton-loader
                v-if="isLoading"
                type="text"
                width="200px"
              ></v-skeleton-loader>
              <template v-else>
                {{ company.name }}
              </template>
            </td>
          </tr>
          <tr>
            <td>ID</td>
            <td>
              <span ref="id">{{ id }}</span>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    class="ma-2"
                    text
                    icon
                    v-if="id"
                    @click.prevent="copyLink"
                  >
                    <img
                      svg-inline
                      src="../../assets/images/ico-copy.svg"
                      alt=""
                    />
                  </v-btn>
                </template>
                <span>Copy</span>
              </v-tooltip>
            </td>
          </tr>
          <tr>
            <td>Created</td>
            <td>
              <v-skeleton-loader
                v-if="isLoading"
                type="text"
                width="200px"
              ></v-skeleton-loader>
              <template v-else>
                {{ formatDate(company.createdAt) }}
              </template>
            </td>
          </tr>
          <tr>
            <td>Manager</td>
            <td>
              <v-skeleton-loader
                v-if="isLoading"
                type="text"
                width="200px"
              ></v-skeleton-loader>
              <template v-else>
                <router-link
                  :to="{ name: 'User', params: { id: manager.userId.id } }"
                >
                  {{ manager.userId.email }}
                </router-link>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-show="isNotGuest"
                      v-on="on"
                      class="ma-2"
                      text
                      icon
                      color="primary"
                      @click.prevent="loginAsCustomer(manager.userId.id)"
                    >
                      <v-icon>login</v-icon>
                    </v-btn>
                  </template>
                  <span>Simulate User</span>
                </v-tooltip>
              </template>
            </td>
          </tr>
          <tr>
            <td>Users</td>
            <td>
              <v-skeleton-loader
                v-if="isLoading"
                type="text"
                width="200px"
              ></v-skeleton-loader>
              <template v-else>
                <ul>
                  <li v-for="user in users" :key="user.id">
                    <router-link
                      :to="{ name: 'User', params: { id: user.userId.id } }"
                      >{{ user.userId.email }}</router-link
                    >
                  </li>
                </ul>
              </template>
            </td>
          </tr>
          <tr>
            <td>Subscription</td>
            <td>
              <v-skeleton-loader
                v-if="isLoading"
                type="text"
                width="200px"
              ></v-skeleton-loader>
              <template v-else-if="company.plan && company.plan.plan">
                <v-simple-table>
                  <tbody>
                    <tr>
                      <td style="width: 150px">Status</td>
                      <td>{{ company.plan.status }}</td>
                    </tr>
                    <tr>
                      <td>Plan</td>
                      <td>{{ company.plan.plan }}</td>
                    </tr>
                    <tr>
                      <td>Period</td>
                      <td>{{ company.plan.period }}</td>
                    </tr>
                    <tr>
                      <td>Last updated</td>
                      <td>
                        {{ formatDate(company.plan.updatedAt) }}
                      </td>
                    </tr>
                    <tr>
                      <td>Current Period</td>
                      <td>
                        {{ formatDate(company.plan.currentPeriodStart * 1000) }}
                        -
                        {{ formatDate(company.plan.currentPeriodEnd * 1000) }}
                      </td>
                    </tr>
                    <tr v-if="company.plan.cancelAt && company.plan.canceledAt">
                      <td>Scheduled to cancel</td>
                      <td>
                        {{ formatDate(company.plan.cancelAt * 1000) }}
                      </td>
                    </tr>
                    <tr v-if="company.plan.canceledAt">
                      <td>Cancelled</td>
                      <td>
                        {{ formatDate(company.plan.canceledAt * 1000) }}
                      </td>
                    </tr>
                    <tr v-if="company.plan.discount">
                      <td style="width: 150px">Coupon</td>
                      <td>
                        <v-simple-table>
                          <tbody>
                            <tr v-if="company.plan.discount.coupon.name">
                              <td width="150px">Name</td>
                              <td>{{ company.plan.discount.coupon.name }}</td>
                            </tr>
                            <tr v-if="company.plan.discount.subscription">
                              <td>Applied to</td>
                              <td>
                                {{ company.plan.discount.subscription }}
                                <v-tooltip top>
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      v-on="on"
                                      class="ma-2"
                                      text
                                      icon
                                      color="primary"
                                      :href="`https://dashboard.stripe.com/subscriptions/${company.plan.discount.subscription}`"
                                      target="_blank"
                                    >
                                      <v-icon>link</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Stripe</span>
                                </v-tooltip>
                              </td>
                            </tr>
                            <tr v-if="company.plan.discount.coupon.amount_off">
                              <td>Amount off</td>
                              <td>
                                {{
                                  company.plan.discount.coupon.amount_off / 100
                                }}
                                {{ company.plan.discount.coupon.currency }}
                              </td>
                            </tr>
                            <tr v-if="company.plan.discount.coupon.percent_off">
                              <td>Percent off</td>
                              <td>
                                {{ company.plan.discount.coupon.percent_off }}%
                              </td>
                            </tr>
                            <tr v-if="company.plan.discount.start">
                              <td>Start</td>
                              <td>
                                {{
                                  formatDate(company.plan.discount.start * 1000)
                                }}
                              </td>
                            </tr>
                            <tr v-if="company.plan.discount.end">
                              <td>End</td>
                              <td>
                                {{
                                  formatDate(company.plan.discount.end * 1000)
                                }}
                              </td>
                            </tr>
                            <tr v-if="company.plan.discount.coupon.duration">
                              <td>Duration</td>
                              <td>
                                {{ company.plan.discount.coupon.duration }}
                              </td>
                            </tr>
                            <tr
                              v-if="
                                company.plan.discount.coupon.duration_in_months
                              "
                            >
                              <td>Duration in months</td>
                              <td>
                                {{
                                  company.plan.discount.coupon
                                    .duration_in_months
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td>Valid</td>
                              <td>
                                {{ company.plan.discount.coupon.valid }}
                              </td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </template>
              <template v-else>-</template>
            </td>
          </tr>
          <tr>
            <td>Crawl Requests</td>
            <td>
              <v-skeleton-loader
                v-if="isLoading"
                type="text"
                width="200px"
              ></v-skeleton-loader>
              <template v-else>
                <td>
                  <router-link
                    :to="{
                      name: 'CompanyCrawlRequests',
                      params: { id: id, name: company.name },
                    }"
                    >{{ companyCrawlRequests }}</router-link
                  >
                </td>
              </template>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import { API } from "../../api";
import { loginAsCustomer, formatDate } from "../../helpers";
import copyText from "../../utils/copyText";

export default {
  name: "CompanyProfile",
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      formatDate,
      loginAsCustomer,
      company: null,
      isLoading: true,
      companyCrawlRequests: 0,
      openActions: false,
    };
  },
  computed: {
    ...mapGetters(["isNotGuest", "isProd"]),
    manager() {
      return this.company?.members.find(
        (member) => member.permissions === "manager"
      );
    },
    users() {
      return this.company?.members.filter(
        (member) => member.permissions !== "manager"
      );
    },
  },
  async created() {
    this.isLoading = true;
    try {
      const [company, visitorNumber] = await Promise.all([
        API.getCompany({ companyId: this.id }),
        API.getVisitorNumber({
          params: {
            ownedBy: this.id,
            type: "crawlReview",
          },
        }),
      ]);
      this.company = company;
      this.companyCrawlRequests = visitorNumber.count;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
    }
  },
  methods: {
    copyLink(e) {
      copyText({ target: this.$refs.id, trigger: e.currentTarget });
    },
    async convertToGrandfatherPlan() {
      this.isLoading = true;

      try {
        const response = await API.convertToGrandfatherPlan(this.id);
        this.company.plan = response;
      } catch (error) {
        console.error("Failed to convert to grandfather plan:", error);
      } finally {
        this.isLoading = false;
      }
    },
    async resetSubscriptionCycle() {
      this.isLoading = true;

      try {
        const response = await API.resetSubscriptionCycle(this.id);
        this.company.plan = response;
      } catch (error) {
        console.error("Failed to reset subscription cycle:", error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
